<template>
  <div>
    <!-- Modal de pesquisa por assunto -->
    <b-modal
      id="modal-search-subject"
      ref="modal-search-subject"
      ok-only
      ok-title="Fechar"
      scrollable
      title="Busca por assunto"
      size="xl"
    >
      <b-overlay :variant="skin" :show="recordingBySubject.loading">
        <Table
          :fields="recordingBySubject.fields"
          :list="recordingBySubject.items"
          border="full"
          style="font-size: 9pt"
          responsive
          @rowClick="onClickSelected"
        >
          <template #id="data" v-if="teacherView">
            <a
              v-if="data.item.id"
              data-text="Ver Materiais"
              class="ellipsis"
              :style="{ textDecoration: 'underline' }"
            >
              {{ data.item.id }}
            </a>
            <div v-else>--</div>
          </template>
          <template #subjects="data">
            <div
              v-for="(mediafile, index) in data.item.recordingMediaFilesDTO"
              :key="index"
            >
              <div
                v-for="(subject, index) in mediafile.mediaSubjectVideo"
                :key="index"
              >
                <b-badge variant="light-info" class="m-25">
                  {{ subject.subject }}
                </b-badge>
              </div>
            </div>
          </template>

          <template #created_at="data">
            {{ statusDateFormatted(data.item.created_at) }}
          </template>
        </Table>
      </b-overlay>
    </b-modal>
    <!-- Filter -->
    <b-button
      class="btn-filter-appoitment btn-icon rounded-circle"
      :variant="'gradient-warning'"
      @click="isActiveFilter = true"
    >
      <feather-icon icon="FilterIcon" size="25" />
    </b-button>

    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isActiveFilter"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isActiveFilter">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Filtros</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-form class="px-1 mt-1">
          <b-row>
            <b-col cols="12">
              <b-form-group label="ID">
                <b-form-input type="number" v-model="recordID" />
              </b-form-group>
            </b-col>

            <b-col class="mb-50" cols="12">Período</b-col>
            <b-col cols="6">
              <b-form-datepicker
                id="datepicker-1"
                v-model="startDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                placeholder="Data Inicial"
                locale="pt-br"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                id="datepicker-2"
                v-model="endDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                placeholder="Data Final"
                locale="pt-br"
              />
            </b-col>

            <b-col class="mt-1" cols="12">
              <b-form-group label="Professor" v-if="!teacherView">
                <v-select
                  v-model="teacherSelected"
                  :options="teachers"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchTeacher(res);
                    }
                  "
                  :loading="teacherLoading"
                >
                  <template v-slot:option="option">
                    <h6 class="text-capitalize m-0">{{ option.label }}</h6>
                    <small v-if="option.email">
                      {{ option.email }}
                    </small>
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Empresa">
                <v-select
                  v-model="companySelected"
                  :options="companies"
                  :clearable="true"
                  autocomplete="off"
                  :disabled="teacherView"
                ></v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Projeto">
                <v-select
                  v-model="projectSelected"
                  :options="projects"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchProject(res);
                    }
                  "
                  :loading="projectLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Evento">
                <v-select
                  v-model="eventSelected"
                  :options="events"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchEvent(res);
                    }
                  "
                  :loading="eventLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Curso">
                <v-select
                  v-model="courseSelected"
                  :options="courses"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchCourse(res);
                    }
                  "
                  :loading="courseLoading"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Tipo de Gravação">
                <v-select
                  v-model="mediaTypeSelected"
                  :options="media_type"
                  :clearable="true"
                  autocomplete="off"
                ></v-select>
              </b-form-group>
            </b-col>

            <b-col cols="6" class="mb-2">
              <b-form-group label="Status da Gravação">
                <v-select
                  v-model="statusRecordingSelected"
                  :options="statusRecording"
                  autocomplete="off"
                  :clearable="true"
                >
                  <template #selected-option="{ label }">
                    <div
                      class="d-inline-block text-truncate"
                      style="max-width: 290px"
                    >
                      {{ label }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="6" class="mb-2">
              <b-form-group label="Status de Edição">
                <v-select
                  v-model="statusSelected"
                  :options="status"
                  autocomplete="off"
                  :clearable="true"
                >
                  <template #selected-option="{ label }">
                    <div
                      class="d-inline-block text-truncate"
                      style="max-width: 290px"
                    >
                      {{ label }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col v-if="statusSelected" cols="6" class="mb-2">
              <b-form-group label="Futura transmissão?">
                <v-select
                  v-model="withFutureTrasmissionSelected"
                  :options="withFutureTrasmission"
                  autocomplete="off"
                  :clearable="true"
                >
                  <template #selected-option="{ label }">
                    <div
                      class="d-inline-block text-truncate"
                      style="max-width: 290px"
                    >
                      {{ label }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="9">
              <b-button @click="filter" variant="info" block>
                FILTRAR
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button @click="clearfilter" variant="primary" block>
                Limpar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
    <!-- VERIFICAR QUEM É O PROFESSOR -->
    <b-modal
      id="modal-recording"
      ref="modal-recording"
      cancel-variant="outline-secondary"
      ok-title="Seguir para gravação"
      cancel-title="Fechar"
      centered
      title="Selecione o professor da gravação"
      ok-variant="info btn-icon"
      @ok="onClickNewRecording"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label="Professor">
          <v-select
            v-model="teacherRecordingSelected"
            :options="teachers"
            :clearable="false"
            autocomplete="off"
            @search="
              (res) => {
                searchTeacher(res);
              }
            "
            :loading="teacherLoading"
          >
            <template v-slot:option="option">
              <h6 class="text-capitalize m-0">{{ option.label }}</h6>
              <small v-if="option.email">
                {{ option.email }}
              </small>
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Pesquisando... <em>{{ search }}</em>
              </template>
              <em style="opacity: 0.5" v-else>Digite o Nome</em>
            </template>
          </v-select>
        </b-form-group>
      </b-form>
    </b-modal>
    <viewcard--c>
      <div class="d-flex justify-content-between mt-2">
        <div class="mb-1">
          <h4>
            <span class="mr-25"> Gravações - Upload Mídias </span>
          </h4>
        </div>
        <div class="float-right mb-1" v-if="teacherView">
          <b-input-group>
            <b-form-input
              placeholder="Buscar por assunto..."
              v-model="subject_text"
            />
            <b-input-group-append>
              <b-button @click="getRecordingBySubjectTeacher" variant="success">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <view--c
        permission="permission.audiovisual.demands.upload.midia.view"
        :busy="isloading"
      >
        <Table
          :fields="fields"
          :list="list"
          @orderBy="getRecordsOrderBy"
          border="full"
          style="font-size: 10px"
          class="text-nowrap mb-0"
          responsive
        >
          <template #id="data">
            <a
              v-if="data.item.id"
              data-text="Ver Materiais"
              :class="{ ellipsis: enabledRedirectLink(data.item) }"
              @click="
                teacherView
                  ? teacherViewRecordingRedirection(data.item.id)
                  : recordingRedirection(data.item)
              "
              :style="{
                textDecoration: enabledRedirectLink(data.item)
                  ? 'underline'
                  : 'none',
              }"
            >
              {{ data.item.id }}
            </a>
            <div v-else>--</div>
          </template>
          <template #created_at="data">
            <div
              v-if="data.item.created_at"
              :data-text="statusDateFormatted(data.item.created_at)"
              class="ellipsis"
            >
              {{ statusDateFormatted(data.item.created_at).split("-")[0] }}
            </div>
            <div v-else>--</div>
          </template>

          <template #event_course="data">
            <div
              v-if="data.item.event_name || data.item.course_name"
              :data-text="data.item.event_name || data.item.course_name"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.event_name || data.item.course_name }}
            </div>
            <div v-else>--</div>
          </template>

          <template #project_name="data">
            <div
              :data-text="data.item.project_name || '--'"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.project_name || "--" }}
            </div>
          </template>

          <template #teacher_name="data">
            <div
              :data-text="data.item.teacher_name || '--'"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.teacher_name || "--" }}
            </div>
          </template>

          <template #company_id="data">
            <div
              v-if="companies.length > 0"
              :data-text="
                companies.filter((f) => f.value == data.item.company_id)[0]
                  ? companies.filter((f) => f.value == data.item.company_id)[0]
                      .label
                  : '--'
              "
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{
                companies.filter((f) => f.value == data.item.company_id)[0]
                  ? companies
                      .filter((f) => f.value == data.item.company_id)[0]
                      .label.split(" ")
                      .filter((f) => f != "Estratégia")
                      .join(" ")
                  : "--"
              }}
            </div>
          </template>

          <template #progress="data">
            <div class="text-center">
              <Tracking
                :id="{ recording_media_id: data.item.id }"
                size="30px"
              />
            </div>
          </template>

          <template #finalized="data">
            <div class="text-center">
              <feather-icon
                v-if="data.item.finalized"
                icon="CheckCircleIcon"
                size="20"
                style="color: #28c76f"
                v-b-tooltip.hover.top="'Pronto!'"
              />
              <feather-icon
                v-else
                icon="CircleIcon"
                size="20"
                v-b-tooltip.hover.top="'Pendente!'"
              />
            </div>
          </template>

          <template #actions="data">
            <div class="text-nowrap">
              <feather-icon
                @click="abrirGravacao(data.item.id)"
                v-if="!teacherView"
                icon="EyeIcon"
                class="mx-75"
                size="16"
                v-b-tooltip.hover.top="'Detalhes'"
              />
              <feather-icon
                v-if="teacherView"
                icon="ExternalLinkIcon"
                :style="{ marginLeft: '2px' }"
                size="16"
                @click="
                  teacherView && teacherViewRecordingRedirection(data.item.id)
                "
                v-b-tooltip.hover.top="`Ver Materiais`"
              />
              <feather-icon
                @click="editarGravacao(data.item.id)"
                v-if="btEdit && !data.item.finalized && !teacherView"
                icon="EditIcon"
                class="mx-75"
                size="16"
                v-b-tooltip.hover.top="'Editar'"
              />
              <feather-icon
                @click="cloneConfirm(data.item)"
                :id="`btn-clone-${data.item.id}`"
                v-if="btClone && data.item.finalized && !teacherView"
                icon="CopyIcon"
                class="mx-75"
                size="16"
                v-b-tooltip.hover.top="
                  data.item.is_clone
                    ? `Clone (Original: ${data.item.parent_id})`
                    : 'Clonar'
                "
              />
              <feather-icon
                @click="finalizedConfirm(data.item)"
                :id="`btn-finish-${data.item.id}`"
                v-if="!data.item.finalized && !teacherView"
                icon="StopCircleIcon"
                class="mx-75"
                size="16"
                v-b-tooltip.hover.top="'Finalizar Demanda'"
              />
              <feather-icon
                v-if="btExclusion && !data.item.finalized && !teacherView"
                @click="confirmExclusion(data.item.id)"
                icon="TrashIcon"
                class="mx-75"
                size="16"
                v-b-tooltip.hover.top="'Apagar'"
              />
            </div>
          </template>
        </Table>
        <div class="mt-2 d-flex justify-content-center">
          <b-button @click="getLoadMore" variant="secondary" pill>
            Carregar mais
          </b-button>
        </div>
      </view--c>
    </viewcard--c>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import {
  BFormDatepicker,
  VBTooltip,
  BModal,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import { mapState, mapMutations } from "vuex";
import Table from "@/components/Table.vue";
import { VBPopover, BPopover } from "bootstrap-vue";
import _recordingMediaService from "@/services/audiovisual/recording-media";
import _teacherService from "@/services/teachers-service";
import _prioritiesService from "@/services/audiovisual/edition-job-service";
import _eventService from "@/services/event-service";
import _authService from "@/services/auth-service";
import _companyService from "@/services/company-service";
import Tracking from "@/components/Tracking.vue";
import recordingOccurence from "../recording/upload-teacher/components/Occurrence.vue";
import _companieService from "@/services/company-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BFormDatepicker,
    Table,
    BPopover,
    Tracking,
    BModal,
    BOverlay,
    BBadge,
    "occurence-custom": recordingOccurence,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  filters: {
    addZero: (value) => {
      if (!value) return "--";
      value = value + "";
      if (value.length > 1) return value;
      return `0${value}`;
    },
  },
  data() {
    return {
      teacherView: false,
      selectedVertical: null,
      btEdit: false,
      btClone: false,
      btExclusion: false,
      isloading: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "id", label: "ID", orderBy: { type: "back" } },
        { key: "created_at", label: "Criação", orderBy: { type: "back" } },
        {
          key: "event_course",
          label: "Evento/Curso",
          orderBy: { type: "back" },
        },
        { key: "project_name", label: "Projeto", orderBy: { type: "back" } },
        { key: "teacher_name", label: "Professor", orderBy: { type: "back" } },
        { key: "company_id", label: "Empresa", orderBy: { type: "back" } },
        { key: "progress", label: "Processo" },
        { key: "finalized", label: "Status" },
        { key: "actions", label: "Ações" },
      ],
      list: [],
      recordingBySubject: {
        loading: false,
        items: [],
        fields: [
          { key: "id", label: "ID", orderBy: { type: "front" } },
          {
            key: "teacher_name",
            label: "Professor",
            orderBy: { type: "front" },
          },
          { key: "created_at", label: "Data", orderBy: { type: "front" } },
          { key: "subjects", label: "Assuntos" },
        ],
      },
      teacherRecordingSelected: { label: null, value: null },
      teacherRecording: { label: null, value: null },
      record_id: 0,
      ascOrDes: false,
      orderByKey: "",
      subjectFilter: "",
      companies: [],
      companyLoading: [],
      isActiveFilter: false,
      teachers: [],
      teacherLoading: false,
      projects: [],
      projectLoading: false,
      events: [],
      eventLoading: false,
      status: [],
      statusLoading: false,
      courses: [],
      courseLoading: false,
      media_type: [
        { label: "Vídeo", value: 0 },
        { label: "Áudio", value: 1 },
      ],
      type_user: {
        type: "common",
        id: 0,
      },
      statusRecordingSelected: null,
      mediaTypeSelected: null,
      statusRecording: [
        { label: "Pendentes", value: 0 },
        { label: "Prontas", value: 1 },
      ],
      withFutureTrasmissionSelected: null,
      withFutureTrasmission: [
        { value: null, label: "Todos" },
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
      ],
      subject_text: "",
    };
  },
  computed: {
    ...mapState({
      currentRecordID: (state) => state.recordingFilter.recordID,
      currentStartDate: (state) => state.recordingFilter.startDate,
      currentEndDate: (state) => state.recordingFilter.endDate,
      currentTeacherSelected: (state) => state.recordingFilter.teacherSelected,
      currentProjectSelected: (state) => state.recordingFilter.projectSelected,
      currentCompanySelected: (state) => state.recordingFilter.companySelected,
      currentEventSelected: (state) => state.recordingFilter.eventSelected,
      currentCourseSelected: (state) => state.recordingFilter.courseSelected,
      currentStatusSelected: (state) => state.recordingFilter.statusSelected,
      currentMediaTypeSelected: (state) =>
        state.recordingFilter.mediaTypeSelected,
      searchSubject: (state) => state.toolbar.operation.searchSubject,
    }),
    recordID: {
      get() {
        return this.currentRecordID;
      },
      set(value) {
        this.UPDATE_RECORD_ID(value);
      },
    },
    startDate: {
      get() {
        return this.currentStartDate;
      },
      set(value) {
        this.UPDATE_SELECTED_STARTDATE(value);
      },
    },
    endDate: {
      get() {
        return this.currentEndDate;
      },
      set(value) {
        this.UPDATE_SELECTED_ENDDATE(value);
      },
    },
    teacherSelected: {
      get() {
        return this.currentTeacherSelected;
      },
      set(value) {
        this.UPDATE_SELECTED_TEACHER(value);
      },
    },
    projectSelected: {
      get() {
        return this.currentProjectSelected;
      },
      set(value) {
        this.UPDATE_SELECTED_PROJECT(value);
      },
    },
    companySelected: {
      get() {
        return this.currentCompanySelected;
      },
      set(value) {
        this.UPDATE_SELECTED_COMPANY(value);
      },
    },
    eventSelected: {
      get() {
        return this.currentEventSelected;
      },
      set(value) {
        this.UPDATE_SELECTED_EVENT(value);
      },
    },
    courseSelected: {
      get() {
        return this.currentCourseSelected;
      },
      set(value) {
        this.UPDATE_SELECTED_COURSE(value);
      },
    },
    statusSelected: {
      get() {
        return this.currentStatusSelected;
      },
      set(value) {
        this.UPDATE_SELECTED_STATUS(value);
      },
    },
  },
  async created() {
    await this.getCompanies();
    this.handleTeacherView();
    this.searchStatus();
    this.getRecords(this.currentePage);
    this.btEdit = this.$utils.isPermited(
      "permission.audiovisual.recorgind.edit"
    );
    this.btClone = this.$utils.isPermited(
      "permission.audiovisual.recording.media.clone"
    );
    this.btExclusion = this.$utils.isPermited(
      "permission.audiovisual.recording.media.exclusion"
    );
    this.getUser();
    document.querySelector(".card-header").style.display = "none";
  },
  mounted() {
    this.UPDATE_CURRENT_TOOLBAR("recording-media");
    this.$root.$on("getRecordingBySubject", () => {
      this.getRecordingBySubject();
    });

    if (
      this.$utils.isPermited(
        "permission.audiovisual.demands.upload.midia.create"
      )
    ) {
      this.$root.$on("newRecording", () => {
        this.newRecording();
      });
    }
  },
  destroyed() {
    this.UPDATE_CURRENT_TOOLBAR("none");
    this.$root.$off("getRecordingBySubject");
  },
  methods: {
    ...mapMutations({
      UPDATE_CURRENT_TOOLBAR: "toolbar/UPDATE_CURRENT_TOOLBAR",
      UPDATE_RECORD_ID: "recordingFilter/UPDATE_RECORD_ID",
      UPDATE_SELECTED_STARTDATE: "recordingFilter/UPDATE_SELECTED_STARTDATE",
      UPDATE_SELECTED_ENDDATE: "recordingFilter/UPDATE_SELECTED_ENDDATE",
      UPDATE_SELECTED_TEACHER: "recordingFilter/UPDATE_SELECTED_TEACHER",
      UPDATE_SELECTED_PROJECT: "recordingFilter/UPDATE_SELECTED_PROJECT",
      UPDATE_SELECTED_COMPANY: "recordingFilter/UPDATE_SELECTED_COMPANY",
      UPDATE_SELECTED_EVENT: "recordingFilter/UPDATE_SELECTED_EVENT",
      UPDATE_SELECTED_COURSE: "recordingFilter/UPDATE_SELECTED_COURSE",
      UPDATE_SELECTED_STATUS: "recordingFilter/UPDATE_SELECTED_STATUS",
      UPDATE_SELECTED_MEDIA_TYPE: "recordingFilter/UPDATE_SELECTED_MEDIA_TYPE",
    }),
    handleTeacherView() {
      const expected_slug = "teacher-view";
      this.teacherView = this.$route.path.includes(expected_slug);
      if (this.teacherView) {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const teacher_id = Number(userData.id);
        this.teacherSelected = {
          value: teacher_id,
        };
        this.selectedVertical = this.$route.params.vertical ?? "concursos";
        this.companySelected = this.companies.findLast(
          (c) => c.slug == this.selectedVertical
        );
      }
    },
    teacherViewRecordingRedirection(recordingMediaID) {
      if (
        process.env.VUE_APP_BO_CONTAINER_URL &&
        this.selectedVertical &&
        recordingMediaID
      ) {
        const boURL = `${process.env.VUE_APP_BO_CONTAINER_URL}/#/${this.selectedVertical}/videos-e-pdfs?recordingMediaID=${recordingMediaID}`;
        window.open(boURL, "_blank");
      }
    },
    recordingRedirection(record) {
      let company = this.companies.find((f) => f.value == record.company_id);
      let vertical = company?.slug;
      if (
        process.env.VUE_APP_BO_CONTAINER_URL &&
        vertical &&
        record.id &&
        record.teacher_external_id
      ) {
        const boURL = `${process.env.VUE_APP_BO_CONTAINER_URL}/#/${vertical}/videos-e-pdfs?recordingMediaID=${record.id}&teacherID=${record.teacher_external_id}`;
        window.open(boURL, "_blank");
      }
    },
    enabledRedirectLink(record) {
      return (
        this.teacherView || (record.company_id && record.teacher_external_id)
      );
    },
    statusDateFormatted(date) {
      if (date) {
        let dateSplited = date.split("T");
        let dateFormatted = dateSplited[0].split("-").reverse().join("/");
        return dateFormatted + " - " + dateSplited[1].substr(0, 8);
      } else {
        return "--";
      }
    },
    totalSubs(files) {
      let total = 0;
      files.forEach((f) => {
        total += f.recordingMediaSubjectUploadDTO.length;
      });
      return total;
    },
    getSubjects(files) {
      let subjects = [];
      files.forEach((f) => {
        f.recordingMediaSubjectUploadDTO.forEach((sub) => {
          subjects.push(sub);
        });
      });
      return subjects;
    },
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      _recordingMediaService
        .show(_page, this.getParams())
        .then((res) => {
          if (res.content) {
            this.list.push(...res.content);
            if (res.content.length > 0) {
              if (isOrder) return;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          // this.orderList();
          this.isloading = false;
        });
    },
    async getCompanies() {
      await _companyService.show().then((res) => {
        if (res.content && res.content.length > 0) {
          this.companies = res.content.map((m) => {
            return {
              value: m.id,
              label: m.name,
              slug: m.slug,
            };
          });
        }
      });
    },
    getLoadMore() {
      this.getRecords(this.currentePage + 1);
    },
    getRecordsOrderBy(_params) {
      this.ascOrDes = _params.ascOrDes;
      this.orderByKey = _params.orderByKey;
      let qtd = this.currentePage;
      this.currentePage = 1;
      this.list = [];
      for (let i = 0; i < qtd; i++) {
        this.getRecords(this.currentePage);
      }
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.list = [];
      this.getRecords(this.currentePage);
      this.isActiveFilter = false;
    },
    clearfilter() {
      this.recordID = null;
      this.startDate = null;
      this.endDate = null;
      if (!this.teacherView) {
        this.teacherSelected = null;
        this.companySelected = null;
      }
      this.projectSelected = null;
      this.eventSelected = null;
      this.courseSelected = null;
      this.mediaTypeSelected = null;
      this.statusSelected = null;
      this.statusRecordingSelected = null;
      this.withFutureTrasmissionSelected = null;
      this.filter();
    },
    getParams() {
      let params = {
        id: this.recordID ? this.recordID : null,
        start_date: this.startDate ? this.startDate : null,
        end_date: this.endDate ? this.endDate : null,
        teacher_id: this.teacherSelected ? this.teacherSelected.value : null,
        project_id: this.projectSelected ? this.projectSelected.value : null,
        company_id: this.companySelected ? this.companySelected.value : null,
        company_slug: this.selectedVertical ? this.selectedVertical : null,
        event_id: this.eventSelected ? this.eventSelected.value : null,
        course_id: this.courseSelected ? this.courseSelected.value : null,
        media_type: this.mediaTypeSelected
          ? Number(this.mediaTypeSelected.value)
          : null,
        status_id: this.statusSelected ? this.statusSelected.value : null,
        withFutureTrasmission: this.withFutureTrasmissionSelected
          ? this.withFutureTrasmissionSelected.value
          : null,
        orderByKey: this.orderByKey,
        ascOrDes: this.ascOrDes,
      };
      if (this.statusRecordingSelected) {
        params.finalized = this.statusRecordingSelected.value == 1;
      }
      return params;
    },
    confirmExclusion(record_id) {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.exclusion(record_id);
        }
      });
    },
    exclusion(record_id) {
      this.isloading = true;
      _recordingMediaService
        .exclusionLogical(record_id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.filter();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    abrirGravacao(id_) {
      this.$router.push({
        path: `/audiovisual/recording/view/${id_}`,
      });
    },
    onClickSelected(record, _) {
      if (this.teacherView) {
        this.teacherViewRecordingRedirection(record.id);
      } else {
        if (this.$utils.isPermited("permission.audiovisual.recorgind.view")) {
          this.$router.push({
            path: `/audiovisual/recording/view/${record.id}`,
          });
        }
      }
    },
    editarGravacao(id_) {
      this.$router.push({
        path: `/audiovisual/recording/edit/${id_}`,
      });
    },
    cloneConfirm(item) {
      let textTitle = item.is_clone
        ? `Deseja clonar a gravação original (${item.parent_id})?`
        : `Deseja clonar essa gravação (${item.id})?`;

      this.$swal({
        title: textTitle,
        text: "-",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero clonar!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            path: `/audiovisual/recording/clone/${item.id}`,
          });
        } 
      });
    },
    searchTeacher(_search) {
      if (_search && _search.length >= 3) {
        if (!this.teacherLoading) {
          this.teacherLoading = true;
          setTimeout(() => {
            _teacherService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.teachers = [];
                  this.teachers = this.populardropdown(
                    res.content,
                    "name",
                    "id",
                    "email"
                  );
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.teacherLoading = false));
          }, 1500);
        }
      }
    },
    searchProject(_search) {
      if (_search && _search.length >= 3) {
        if (!this.projectLoading) {
          this.projectLoading = true;
          setTimeout(() => {
            _recordingMediaService
              .autoCompleteEditionProject(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.projects = [];
                  res.content.forEach((element) => {
                    this.projects.push({
                      value: element.project_id,
                      label: element.project_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.projectLoading = false));
          }, 1500);
        }
      }
    },
    searchEvent(_search) {
      if (_search && _search.length >= 3) {
        if (!this.eventLoading) {
          this.eventLoading = true;
          setTimeout(() => {
            _recordingMediaService
              .autoCompleteEditionEvent(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.events = [];
                  res.content.forEach((element) => {
                    this.events.push({
                      value: element.event_id,
                      label: element.event_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.eventLoading = false));
          }, 1500);
        }
      }
    },
    searchCourse(_search) {
      if (_search && _search.length >= 3) {
        if (!this.courseLoading) {
          this.courseLoading = true;
          setTimeout(() => {
            _recordingMediaService
              .autoCompleteEditionCourse(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.courses = [];
                  res.content.forEach((element) => {
                    this.courses.push({
                      value: element.course_id,
                      label: element.course_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.courseLoading = false));
          }, 1500);
        }
      }
    },
    searchStatus(_search) {
      setTimeout(() => {
        _prioritiesService
          .showall()
          .then((res) => {
            if (res.content && res.content.length > 0) {
              this.status = [];
              res.content.forEach((element) => {
                this.status.push({
                  value: element.id,
                  label: element.name,
                });
              });
            }
            this.status.push({
              value: 0,
              label: "Pendente",
            });
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.statusLoading = false));
      }, 1500);
    },
    newRecording() {
      if (this.type_user.type == "teacher") {
        this.recordingTeacher(this.type_user.id);
      } else {
        if (!this.teacherView) {
          this.teacherSelected = { label: null, value: null, email: null };
          this.$refs["modal-recording"].show();
        }
      }
    },
    recordingTeacher(teacher) {
      this.getRecordingActiveTeacher(teacher);
    },
    openNewRecording(teacher) {
      this.$router.push({
        path: `/audiovisual/recording/new/${teacher}`,
      });
    },
    onClickNewRecording() {
      if (!this.teacherRecordingSelected.value) {
        this.$utils.toastError("OPS!!!", "Informe o professor");
      } else {
        this.recordingTeacher(this.teacherRecordingSelected.value);
      }
    },
    getUser() {
      this.isloading = true;
      _authService
        .getAuthUser()
        .then((res) => {
          if (res.content && res.content.type) {
            this.type_user = {
              type: res.content.type,
              id: res.content.id,
            };
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getRecordingActiveTeacher(teacher) {
      this.isloading = true;
      _recordingMediaService
        .getRecordingActiveTeacher(teacher)
        .then((res) => {
          if (res.content && res.content.id && res.content.id > 0) {
            this.recordingActiveAlert(res.content.id);
          } else {
            this.openNewRecording(teacher);
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    recordingActiveAlert(id_recording) {
      this.$swal({
        title: `Foi identificada uma gravação (ID: ${id_recording}) ainda não finalizada, deseja editá-la? `,
        text: "-",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero editar!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.editarGravacao(id_recording);
        }
      });
    },
    populardropdown(_list, _texto, _valor, _email) {
      let retorno = [];
      retorno.push(
        ..._list.map((m) => {
          return {
            label: m[_texto],
            value: m[_valor],
            email: m[_email],
          };
        })
      );
      return retorno;
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    finalizedConfirm(item) {
      this.$swal({
        title: "Deseja finalizar a gravação ?",
        text: "-",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero finalizar!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            // não realiza a validação de produtos pendentes se a gravação não tiver agendamento
            if (this.$swal.getValidationMessage().textContent || !item.appointment_id) {
              return
            };

            let { content } = await _recordingMediaService.allCompletedProducts(item.id);

            if (!content.completed) {
              const products = content.incomplete_products.map((m) => m.name);
              this.$swal.showValidationMessage(
                `ATENÇÃO: Os produtos '${products.join(
                  ", "
                )}' ainda não foram contemplados. Tem certeza que deseja finalizar a gravação?`
              );
              return false;
            }
          } catch (error) {
            this.$utils.toastError("Notificação", error);
            return false;
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (result.value) {
          this.canFinish(item);
        }
      });
    },
    canFinish(item) {
      this.isloading = true;
      _recordingMediaService
        .canFinish(item.id)
        .then((res) => {
          if (res.content.can_finish) {
            this.finish(item);
          } else {
            this.isloading = false;
            let messageValidation = "Não é permitido finalizar";
            //media_file
            if (res.content.error_finish == 1) {
              messageValidation = "Existe material sem informação de assunto.";
            } else if (res.content.error_finish == 2) {
              messageValidation = "Existe operação sem finalização.";
            }
            this.$utils.toastError("Notificação", messageValidation);
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally();
    },
    finish(item) {
      this.isloading = true;
      _recordingMediaService
        .finish(item.id)
        .then((res) => {
          if (res.content && res.content.id > 0) {
            this.$utils.toast(
              "Notificação",
              "Gravação finalizada com sucesso."
            );
            item.finalized = true;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getRecordingBySubjectTeacher() {
      let company_slug = this.selectedVertical ? this.selectedVertical : null;
      this.recordingBySubject.fields = this.recordingBySubject.fields.filter(
        (e) => e.key !== "teacher_name"
      );
      this.getRecordingBySubject(this.subject_text, company_slug);
    },
    getRecordingBySubject(subject_text = null, company_slug = null) {
      this.recordingBySubject.loading = true;
      this.$refs["modal-search-subject"].show();

      let searchText = "";
      if (subject_text) searchText = subject_text;
      else searchText = this.searchSubject;

      this.recordingBySubject.items = [];

      _recordingMediaService
        .getRecordingBySubject(searchText, company_slug)
        .then((res) => {
          if (res.content) {
            this.recordingBySubject.items = res.content.sort((a, b) => {
              if (a.id > b.id) return -1;
              if (a.id < b.id) return 1;
              return 0;
            });
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.recordingBySubject.loading = false));
    },
  },
};
</script>

<style scoped>
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  max-width: 250px;
  padding: 0.5rem 0rem;
}

.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}

.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
}

.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}
</style>
